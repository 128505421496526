import React, { useEffect, useState } from "react";
import DonateOne from "./DonateOne/DonateOne";
import "./Donate.css";
import DonationDetails from "./DonationDetails/DonationDetails";
import ConnectToShiksha from "./ConnectToShiksha/ConnectToShiksha";

function Donate() {
  // const [Style, setStyle] = useState({});
  // const height = window.innerHeight;
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   const styleOn = {
  //     opacity: 0,
  //     position: "fixed",
  //   };
  //   const styleOff = {
  //     position: "fixed",
  //   };
  //   if (position > height - 300) {
  //     setStyle(styleOn);
  //     if (position > height) {
  //       setStyle({
  //         position: "relative",
  //       });
  //     }
  //   } else {
  //     setStyle(styleOff);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div className="Donate">
      <div className="Donate_Head">
        <img
          className="Donate_HeadImg"
          alt="Donate for a cause"
          src="https://firebasestorage.googleapis.com/v0/b/shiksha-welfare.appspot.com/o/Donation%2FDonatePoster_HeadDesktop.jpeg?alt=media&token=79d8471f-ffe2-448b-b19a-4adc3166c373"
        />
        <div className="Donate_HeadContent">
          <h2>
            HELP US TRANSFORM <br />A STUDENT LIFE
          </h2>
          <p>
            We work towards creating quality and equitable opportunities for
            children and communities to learn and grow with a view to long term
            sustainable change. We fund tution fees , hostel feels and other
            rentals fees for the needy students to provide them support.
          </p>
        </div>
      </div>
      <DonationDetails />
      <ConnectToShiksha />
      {/* <DonateOne></DonateOne> */}
    </div>
  );
}

export default Donate;
