import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { useStateValue } from "../../../StateProvider";
import "./AdminMentorList.css";

function MentorRow({ mentor, mentorShow, mentorHide, index }) {
  return (
    <tr>
      <td>{index}</td>
      <td>
        <img
          style={{ width: "100px", maxHeight: "100px", objectFit: "cover" }}
          alt="mentor"
          src={mentor.Image}
        ></img>
      </td>

      <td>{mentor["Full Name"]}</td>
      <td style={{ fontWeight: "bold" }}>{mentor["Current Profile"]}</td>
      <td>{mentor["Current Profile Description"]}</td>
      <td>{mentor["Email"]}</td>
      <td>{mentor["Phone"]}</td>
      <td>{mentor["Gender"]}</td>
      <td>{mentor["School"]}</td>
      <td>{mentor["Schooling City"]}</td>
      <td>{mentor["Highest Degree"]}</td>
      <td>{mentor["University Name"]}</td>
      <td>{mentor["Year"]}</td>
      <td>{mentor["Current City"]}</td>
      <td className="longtext_mentor">{mentor["Description"]}</td>
      <td>{mentor.order}</td>

      {!mentor.show ? (
        <td
          className="green hover"
          onClick={(e) => {
            mentorShow({ e, mentor });
          }}
        >
          {" "}
          Mark Visible
        </td>
      ) : (
        <td
          className="red hover"
          onClick={(e) => {
            mentorHide({ e, mentor });
          }}
        >
          {" "}
          remove
        </td>
      )}
    </tr>
  );
}

function MentorList() {
  const [Mentors, setMentors] = useState([]);
  const [{ user }] = useStateValue();
  const fetchMentors = async () => {
    const q = query(
      collection(db, "Mentors"),
      orderBy("Current Profile", "asc")
    );
    const querySnap = await getDocs(q);
    const mentors = [];
    querySnap.forEach((doc) => {
      mentors.push({ id: doc.id, ...doc.data() });
    });
    setMentors(mentors);
  };

  const mentorShow = async ({ e, mentor }) => {
    e.preventDefault();
    const ref = doc(db, "Mentors", mentor.id);
    const UpdatedMentor = mentor;
    UpdatedMentor.show = true;
    await setDoc(ref, UpdatedMentor);
    fetchMentors();
  };

  const mentorHide = async ({ e, mentor }) => {
    e.preventDefault();
    const ref = doc(db, "Mentors", mentor.id);
    const UpdatedMentor = mentor;
    UpdatedMentor.show = false;
    await setDoc(ref, UpdatedMentor);
    fetchMentors();
  };
  useEffect(() => fetchMentors(), []);
  return (
    user &&
    user.superAdmin && (
      <div className="AdminMentorList">
        <h2>Mentors</h2>
        <div className="AdminMentorList_TableCover">
          <table>
            <tr>
              <th>S.No.</th>
              <th>Picture</th>
              <th>Full Name</th>
              <th>Current Profile</th>
              <th>Current Profile Description</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Gender</th>
              <th>School</th>
              <th>Schooling City</th>
              <th>Highest Degree</th>
              <th>University Name</th>
              <th>Year</th>
              <th>Current City</th>
              <th>Description</th>
              <th>order</th>
              <th>Show/Hide</th>
            </tr>
            {Mentors.map((mentor, index) => (
              <MentorRow
                mentor={mentor}
                key={index}
                index={index + 1}
                mentorHide={mentorHide}
                mentorShow={mentorShow}
              />
            ))}
          </table>
        </div>
      </div>
    )
  );
}

export default MentorList;
