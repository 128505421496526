import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import "./AdminMentee.css";
import { db } from "../../../firebase";

function MenteeDetail({ stud, index }) {
  return (
    <tr>
      <td>{index}</td>
      <td>{stud["Full Name"]}</td>
      <td>{stud["Feild Intrested"]}</td>
      <td>{stud["Phone"]}</td>
      <td>{stud["Email"]}</td>
      <td>{stud["Current City"]}</td>
      <td>{stud["Description"]}</td>
    </tr>
  );
}

function AdminMentee() {
  const [Mentee, setMentee] = useState([]);
  const fetchMentee = async () => {
    const q = query(collection(db, "Mentees"), orderBy("Full Name", "asc"));
    const querySnap = await getDocs(q);
    const mentee = [];
    querySnap.forEach((doc) => {
      mentee.push({ id: doc.id, ...doc.data() });
    });
    setMentee(mentee);
  };
  useEffect(() => {
    fetchMentee();
  }, []);

  return (
    <div className="AdminMentee">
      <div className="AdminMentee_Table">
        <table>
          <tr>
            <th>S. No.</th>
            <th>Name</th>
            <th>Feild Intrested</th>
            <th>Phone</th>
            <th>Email</th>
            <th>City</th>
            <th>Description</th>
          </tr>

          {Mentee?.map((stud, index) => (
            <MenteeDetail
              stud={stud}
              index={index + 1}
              key={index}
            ></MenteeDetail>
          ))}
        </table>
      </div>
    </div>
  );
}

export default AdminMentee;
