import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { useStateValue } from "../../../StateProvider";
import "./AdminUsers.css";
function AdminUsers() {
  const [Users, setUsers] = useState([]);

  const fetchData = async () => {
    const ref = collection(db, "Users");
    const snap = await getDocs(ref);
    const Data = [];
    snap.forEach((user) => {
      const data = user.data();

      Data.push(data);
    });

    setUsers(Data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const makeAdmin = async ({ e, user }) => {
    e.preventDefault();
    const ref = doc(db, "Users", user.uid);
    const UpdatedUser = user;
    UpdatedUser.admin = true;
    await setDoc(ref, UpdatedUser);
    fetchData();
  };
  const removeAdmin = async ({ e, user }) => {
    e.preventDefault();
    const ref = doc(db, "Users", user.uid);
    const UpdatedUser = user;
    UpdatedUser.admin = false;
    await setDoc(ref, UpdatedUser);
    fetchData();
  };
  const [{ user }] = useStateValue();

  return (
    user &&
    user.superAdmin && (
      <div className="AdminUsers">
        <h2>ADMIN ACCESS</h2>
        <table>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Admin Access</th>
          </tr>
          {Users?.map(
            (user) =>
              !user?.superAdmin && (
                <tr key={user.uid}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>

                  {user?.admin ? (
                    <td
                      className="red hover"
                      onClick={(e) => {
                        removeAdmin({ e, user });
                      }}
                    >
                      {" "}
                      Remove Admin{" "}
                    </td>
                  ) : (
                    <td
                      className="green hover"
                      onClick={(e) => {
                        makeAdmin({ e, user });
                      }}
                    >
                      Make Admin
                    </td>
                  )}
                </tr>
              )
          )}
        </table>
      </div>
    )
  );
}

export default AdminUsers;
