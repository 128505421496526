import {
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useLocation, useNavigate } from "react-router-dom";
import { db, collection, addDoc, storage } from "../../firebase";
import { useStateValue } from "../../StateProvider";
import useIntersection from "../elements/useTntersection";
import "./ShikshaStories.css";

function HeadStory() {
  const query = new URLSearchParams(useLocation().search);
  const StoryId = query.get("id");
  const ref = useRef();
  const [story, setStory] = useState();
  const getData = async () => {
    const ref = doc(db, "Stories", StoryId);
    const data = await getDoc(ref).then((ref) => ref.data());
    setStory(data);
  };
  useEffect(() => {
    if (StoryId) {
      getData();
    }
  }, [StoryId]);
  const navigate = useNavigate();
  const moveBack = (e) => {
    e.preventDefault();
    navigate("/shikshaStories");
  };
  return StoryId && story ? (
    <div ref={ref} className="ShikshaHeadStory flex">
      <div
        className="flex col ShikshaHeadStory_left"
        style={{
          justifyContent: "space-between",
        }}
      >
        <img src={story.Image} alt={story.Name} />
        <h2>{story.Name}</h2>
        <h4>{story.Designation}</h4>
      </div>
      <div className="ShikshaHeadStory_right">
        <h1>{story.Heading}</h1>
        <p>{story.Story}</p>
        {story.YouTube ? (
          <small>
            <a href={story.YouTube}> Watch Full Story on YouTube </a>
          </small>
        ) : (
          <></>
        )}
        <small className="hover" onClick={moveBack}>
          View Less
        </small>
      </div>
    </div>
  ) : (
    <Fragment></Fragment>
  );
}

function Story({ story }) {
  // const [show, setshow] = useState(false);
  // const [viewMore, setViewMore] = useState(true);
  const navigate = useNavigate();

  const ChangeStyle = () => {
    navigate(`/shikshaStories?id=${story.id}`);
    window.scrollTo((0, 0), { behavior: "smooth" });
  };
  return (
    <div
      className="ShikshaStory flex hover"
      style={{
        alignItems: "flex-start",
      }}
      onClick={ChangeStyle}
    >
      <div
        className="flex col ShikshaStory_left"
        style={{
          justifyContent: "space-between",
        }}
      >
        <img src={story.Image} alt={story.Name} />
        <h2>{story.Name}</h2>
        <h4>{story.Designation}</h4>
      </div>
      <div className="ShikshaStory_right">
        <h1>{story.Heading}</h1>
        <p>{story.Story}</p>
        <small className="link">View More</small>
      </div>
    </div>
  );
}

function ShikshaStories() {
  const [{ user }] = useStateValue();
  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  const showAddStory = (e) => {
    e.preventDefault();
    if (!user) {
      alert(
        "To add a story You must Login first. If you are new , you can register as a member."
      );
      navigate("/auth?ref=register");
    } else {
      setshow(true);

      addstoryref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });
  const ImageUrl = async (image) => {
    console.log("Image Resizing");
    const file = await new Promise((resolve, reject) => {
      resizeFile(image).then((obj) => resolve(obj));
      setTimeout(() => {
        reject(Error("Promise rejected"));
      }, 500);
    });

    const storageRef = ref(storage, `Stories/${image.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    console.log("URL generated : ", url);
    return url;
  };
  const [submitButton, setsubmitButton] = useState(true);
  const addStory = async (e) => {
    if (!user) {
      alert(
        "To add a story You must Login first. If you are new , you can register as a member."
      );
      return;
    }
    setsubmitButton(false);
    e.preventDefault();
    const getDetails = async () => {
      var details = {};
      [...e.target.elements].map(async (element) => {
        if (element.name) {
          if (element.name === "Image") {
            if (element.files[0])
              details[element.name] = ImageUrl(element.files[0]);
          } else {
            details[element.name] = element.value;
          }
        }
      });
      details["timestamp"] = serverTimestamp();
      details["show"] = false;
      details["priority"] = 3;
      details["uid"] = user.uid;

      if (await details["Image"]) {
        details["Image"] = await details["Image"];
        console.log(details, "det");
        return details;
      } else {
        return details;
      }
    };

    try {
      const docRef = await addDoc(
        collection(db, "Stories"),
        await getDetails()
      );
      e.target.reset();
      fetchData();
      console.log("Documents written with ID: ", docRef.id);
      alert(
        "Your story is successfully created. It will be published after verification"
      );
      setsubmitButton(true);
    } catch (e) {
      console.log("Error adding document: ", e);
      alert("Error adding document, Please try again with different image");
      setsubmitButton(true);
    }
  };

  // const deleteStory = async (id) => {
  //   await deleteDoc(doc(db, "Stories", id));
  //   fetchData();
  // };

  const [Stories, setStories] = useState([]);
  const [DisplayedStories, setDisplayedStories] = useState([]);
  const [IndexAdd, setIndexAdd] = useState(0);
  const fixStoriesShow = 10;

  const refrence = useRef();
  const addstoryref = useRef();
  const inview = useIntersection(refrence, false);

  const LoadData = () => {
    if (Stories.length > IndexAdd) {
      const addImages = Stories.slice(IndexAdd, IndexAdd + fixStoriesShow);
      setIndexAdd(IndexAdd + fixStoriesShow);
      setDisplayedStories((x) => [...x, ...addImages]);
    }
  };

  useEffect(() => {
    LoadData();
  }, [inview, Stories]);

  const fetchData = async () => {
    const q = query(collection(db, "Stories"), orderBy("priority", "asc"));
    const querySnapshot = await getDocs(q);
    const stories = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const details = {
        id: doc.id,
        ...data,
      };
      stories.push(details);
    });
    setStories(stories);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 800 || position < 100) {
      setscrollShow(false);
    } else {
      setscrollShow(true);
    }
  };
  const [scrollShow, setscrollShow] = useState(true);
  useEffect(() => {
    fetchData();
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="ShikshaStories">
      <div className="ShikshaStories_Head flex">
        <img
          src="./media/ShikshaStoriesBackground_Desktop.jpg"
          alt="Shiksha success stories"
        />
        <div className="ShikshaStories_HeadContent">
          <h2>
            SHIKSHA <br /> STORIES
          </h2>
          <p> Thousands of stories from a single place.</p>
        </div>
      </div>
      <HeadStory />
      <div className="ShikshaStories_Content flex ">
        {DisplayedStories.map(
          (story, index) =>
            story.show && (
              <Story
                story={story}
                key={index}
                // user={user}
              />
            )
        )}
      </div>
      <div className="ShikshaStories_addstories" ref={refrence}></div>

      {!show && scrollShow && (
        <small className="hover  addyourstory" onClick={showAddStory}>
          Add your story{" "}
        </small>
      )}
      {user && show && (
        <form onSubmit={addStory} className="ShikshaStories_Input flex col">
          <h2>Add a Story</h2>
          <div className="ShikshaStory_input">
            <label htmlFor="ShikshaStory_Name">Name</label>
            <input required id="ShikshaStory_Name" name="Name"></input>
          </div>
          <div className="ShikshaStory_input">
            <label htmlFor="ShikshaStory_Designation">Designation</label>
            <input
              required
              id="ShikshaStory_Designation"
              name="Designation"
            ></input>
          </div>
          <div className="ShikshaStory_input">
            <label htmlFor="ShikshaStory_Heading">Heading</label>
            <input required id="ShikshaStory_Heading" name="Heading"></input>
          </div>
          <div className="ShikshaStory_input">
            <label htmlFor="ShikshaStory_Story">Story</label>
            <textarea id="ShikshaStory_Story" required name="Story"></textarea>
          </div>
          <div className="ShikshaStory_input">
            <label htmlFor="ShikshaStory_Image">
              Image<small>(Square dimensions are preferred)</small>
            </label>
            <input
              type="file"
              required
              accept=".png, .jpg, .jpeg"
              id="ShikshaStory_Image"
              name="Image"
            ></input>
          </div>
          <div className="ShikshaStory_input">
            <label htmlFor="ShikshaStory_YouTube">
              YouTube <small>(If any)</small>
            </label>
            <input id="ShikshaStory_YouTube" name="YouTube"></input>
          </div>
          {submitButton ? (
            <button type="submit">Submit</button>
          ) : (
            <p>Submitting</p>
          )}
        </form>
      )}
      <div ref={addstoryref}></div>
    </div>
  );
}

export { ShikshaStories, Story };
