import React from "react";
import { useNavigate } from "react-router-dom";
import "./Register.css";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../../../firebase";

function Register() {
  const auth = getAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.Register_Name.value;
    const email = e.target.Register_username.value.split(" ").join("");
    const password = e.target.Register_password.value;
    console.log(`Email : ${email} \n Password : ${password}`);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(`User : ${user.uid}`);
        const ref = doc(db, "Users", user.uid);
        const userDetails = {
          name,
          email,
          uid: user.uid,
          admin: false,
          date: serverTimestamp(),
          superAdmin: false,
        };
        setDoc(ref, userDetails);
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Error : ${errorCode} : ${errorMessage}`);
        alert(errorMessage);
        // ..
      });
  };
  const navigate = useNavigate();
  const navToRegister = () => {
    navigate("/auth?ref=login");
  };
  return (
    <div className="Register">
      <div className="Register_form-Cover">
        <h4>Register</h4>
        <form onSubmit={handleSubmit} className="Register_form">
          <div className="Register_formRow">
            <label htmlFor="Register_Name">Full Name</label>
            <input id="Register_Name" name="Name"></input>
          </div>
          <div className="Register_formRow">
            <label htmlFor="Register_username">Email</label>
            <input id="Register_username" name="Username"></input>
          </div>
          <div className="Register_formRow">
            <label htmlFor="Register_password">Password</label>
            <input
              type="password"
              id="Register_password"
              name="Password"
            ></input>
          </div>
          <button type="submit">Register</button>
        </form>
        <small className="hover" onClick={navToRegister}>
          Already a member
        </small>
      </div>
    </div>
  );
}

export default Register;
