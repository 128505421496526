import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import "./AdminMembers.css";
import { db } from "../../../firebase";
import { useStateValue } from "../../../StateProvider";

function MemberRow({ member, index, fetchData }) {
  const showHide = async (e) => {
    e.preventDefault();
    const ref = doc(db, "Member", member.id);
    const UpdatedMember = member;
    UpdatedMember.show = !member.show;
    await setDoc(ref, UpdatedMember);
    fetchData();
  };

  return (
    <tr>
      <td>{index}</td>
      <td>
        <img
          style={{ width: "100px", height: "100px", objectFit: "cover" }}
          src={member.Photo}
          alt="member"
        ></img>
      </td>
      <td>{member.Name}</td>
      <td>{member.Email}</td>
      <td>{member.Phone}</td>
      <td>{member.Designation}</td>
      <td>{member.Role === "other" ? member.role_input : member.Role}</td>
      <td>
        {member.show ? (
          <p className="red hover" onClick={showHide}>
            Hide
          </p>
        ) : (
          <p className="green hover" onClick={showHide}>
            Show
          </p>
        )}
      </td>
    </tr>
  );
}

function AdminMembers() {
  const [Members, setMembers] = useState([]);
  const [{ user }] = useStateValue();
  const fetchData = async () => {
    const ref = collection(db, "Member");
    const snap = await getDocs(ref);
    const Data = [];
    snap.forEach((member) => {
      const data = member.data();
      const details = { ...data, id: member.id };
      Data.push(details);
    });

    setMembers(Data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    user &&
    user.superAdmin && (
      <div className="AdminMembers">
        <div className="AdminMembers_tableCover">
          <table>
            <tr>
              <th>S. No.</th>
              <th>Photo</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Description</th>
              <th>Role</th>
              <th>Show</th>
            </tr>
            {Members?.map((member, index) => (
              <MemberRow
                member={member}
                index={index + 1}
                key={member.id}
                fetchData={fetchData}
              />
            ))}
          </table>
        </div>
      </div>
    )
  );
}

export default AdminMembers;
