import React, { useState } from "react";
import "./DonationDetails.css";

function DonationDetails() {
  const AccDetails = (
    <div className="DonationDetails_bankDetails">
      <h6>Cheque/DD/NEFT</h6>
      <table>
        <tr>
          <td>For </td>
          <td>
            <strong> SHIKSHA WELFARE SOCIETY </strong>
          </td>
        </tr>
        <tr>
          <td>IFS CODE </td>
          <td>
            <strong>PUNB0224910</strong>
          </td>
        </tr>
        <tr>
          <td>A/C No </td>
          <td>
            <strong>22491012001048</strong>
          </td>
        </tr>
        <tr>
          <td>Bank </td> <td> Punjab National Bank </td>
        </tr>
        <tr>
          <td>Branch </td> <td> Tilak Nagar Indore, 452016 </td>
        </tr>
        <tr>
          <td>PAN </td> <td> ABEAS8939E </td>
        </tr>
        <tr>
          <td>UPI </td> <td> Q25649736@ybl </td>
        </tr>
      </table>
    </div>
  );

  const scanAndPay = (
    <div className="DonationDetails_scanPay">
      <h6>Scan {`&`} Donate</h6>
      <img
        className="DonationDetails_QR"
        src="/media/QR.jpeg"
        alt="donate QR"
      />
    </div>
  );
  const [Index, setIndex] = useState(0);
  const changeIndex = (e) => {
    e.preventDefault();
    const index = e.target.attributes.index.value;
    setIndex(index);
    console.log("INDEX : ", Index, Index === "0", Index === "1", Index === "2");
  };
  const tempSwitch = (
    <>
      {" "}
      <div className="DonationDetails_left">
        <h2> DONATE us via</h2>
        <p className="hover" onClick={changeIndex} index={0}>
          Cheque/DD/NEFT
        </p>
        <p className="hover" onClick={changeIndex} index={1}>
          Scan and Donate
        </p>
        <p className="hover" onClick={changeIndex} index={2}>
          {" "}
          UPI{" "}
        </p>
      </div>
      <div className="DonationDetails_right">
        {Index === "0" ? AccDetails : Index === "1" ? scanAndPay : <h1>UPI</h1>}
      </div>{" "}
    </>
  );
  return (
    <div className="DonationDetails">
      <div className="DonationDetails_heading">
        <h3>PAY NOW ANONYMOUSLY</h3>
      </div>
      <div className="DonationDetails_Donate">
        <div>{AccDetails}</div>
        <div>{scanAndPay}</div>
      </div>
    </div>
  );
}

export default DonationDetails;
