import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useStateValue } from "../../../StateProvider";

function Login() {
  const [{ user }] = useStateValue();
  const auth = getAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.login_username.value.split(" ").join("");
    const password = e.target.login_password.value;
    console.log(`Email : ${email} \n Password : ${password}`);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(`Login Succesfull`);
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Error : ${errorCode}, ${errorMessage}`);
        alert(errorMessage);
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);
  const navToRegister = () => {
    navigate("/auth?ref=register");
  };
  return (
    <div className="Login">
      <div className="Login_form-Cover">
        <h4>Login</h4>
        <form onSubmit={handleSubmit} className="Login_form">
          <div className="Login_formRow">
            <label htmlFor="login_username">Email</label>
            <input type="email" id="login_username" name="Email"></input>
          </div>
          <div className="Login_formRow">
            <label htmlFor="login_password">Password</label>
            <input type="password" id="login_password" name="Password"></input>
          </div>
          <button type="submit">Login</button>
        </form>
        <small className="hover" onClick={navToRegister}>
          Register as a member
        </small>
      </div>
    </div>
  );
}

export default Login;
