import React from "react";

function RefundPolicy() {
  return (
    <div className="privacypolicy">
      <div className="privacypolicy_wrapper">
        <h2>Refund Policy</h2>
        <p>
          Shikhsa Welfare Organisation does not entertain refunds but if there
          are special circumstances you can contact undersigned.{" "}
        </p>
        <ul>
          <li>Deepak Rahangdale</li>
          <li>Phone number: +91-9827745259</li>
        </ul>
      </div>
    </div>
  );
}

export default RefundPolicy;
