import React, { useEffect } from "react";
import "./panel.css";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../StateProvider";

function AdminPanel() {
  const navigate = useNavigate();
  const [{ user }] = useStateValue();
  const navigateTo = (e) => {
    e.preventDefault();
    navigate(`.${e.target.attributes.href.value}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    user &&
    user.superAdmin && (
      <div className="Admintpanel">
        <p onClick={navigateTo} href="/mentees">
          Mentees
        </p>
        <p onClick={navigateTo} href="/members">
          Members
        </p>
        <p onClick={navigateTo} href="/mentors">
          Mentor
        </p>
        <p onClick={navigateTo} href="/stories">
          Stories
        </p>
        <p onClick={navigateTo} href="/users">
          Users
        </p>
      </div>
    )
  );
}

export default AdminPanel;
