import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { getAuth, signOut } from "firebase/auth";
function Navbar() {
  const [Show, setShow] = useState(false);
  const navigate = useNavigate();
  const [style, setstyle] = useState({});
  const [styleImage, setstyleImage] = useState({});
  const [{ user }] = useStateValue();
  const [Hide, setHide] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const hide = query.get("hide");
  useEffect(() => {
    if (hide) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [hide]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    const styleOn = {
      position: "fixed",
      backgroundColor: "white",
      borderBottom: "0.5px solid rgb(200, 200, 200)",
      color: "rgb(70, 69, 69)",
    };
    const styleOff = {
      position: "fixed",
      backgroundColor: "rgba(0,0,0,0.3)",
    };
    const styleImageOn = {
      height: "55px",
    };
    const styleImageOff = {
      height: "100px",
    };
    if (position > 100) {
      setstyle({ ...styleOn });
      setstyleImage({ ...styleImageOn });
    } else {
      setstyle({ ...styleOff });
      setstyleImage({ ...styleImageOff });
    }
  };
  const logout = (e) => {
    e.preventDefault();
    const auth = getAuth();
    signOut(auth)
      .then()
      .catch((error) => {
        // An error happened.
        console.log("ERROR : ", error);
        alert(error);
      });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const NavRoute = (e) => {
    e.preventDefault();
    const route = e.target.attributes.href.value;
    navigate(route);
  };
  const changeShow = (e) => {
    e.preventDefault();
    setShow((shows) => !shows);
  };
  const navbarRight = (
    <div className="Navbar-right">
      <p className="nav-links" onClick={NavRoute} href="/">
        HOME
      </p>
      <p className="nav-links " onClick={NavRoute} href="/mentors">
        SHIKSHA KE MENTORS
      </p>
      <p className="nav-links" onClick={NavRoute} href="/shikshaStories">
        SHIKSHA STORIES
      </p>
      <p className="nav-links" onClick={NavRoute} href="/gallery">
        GALLERY
      </p>

      <div className="nav_dropdown">
        <span className="flex">
          <p> ABOUT </p> <small> ➦ </small>
        </span>
        <div className="nav-dropdown-menu ">
          <p className="nav-links " onClick={NavRoute} href="/updates">
            UPDATES
          </p>
          <p className="nav-links " onClick={NavRoute} href="/members">
            MEMBERS
          </p>
          <p className="nav-links " onClick={NavRoute} href="/aboutUs">
            ABOUT US
          </p>
          {user ? (
            <p className="nav-links " onClick={logout}>
              Logout
            </p>
          ) : (
            <p className="nav-links " onClick={NavRoute} href="/auth?ref=login">
              Member Login
            </p>
          )}
          {user && user?.superAdmin && (
            <p className="nav-links" onClick={NavRoute} href="/admin">
              SuperAdmin
            </p>
          )}
        </div>
      </div>
      <p
        className="nav-links nav-donate"
        onClick={NavRoute}
        href="/donate?hide=true"
      >
        DONATE NOW
      </p>
    </div>
  );

  const hambar = (
    <div className="Navbar-right-hambar-mobile">
      <div className="Navbar-right-hambar-trigger" onClick={changeShow}>
        <p>—</p>
        <p>—</p>
        <p>—</p>
      </div>
      <div
        className="Navbar-right-hambar-background"
        onClick={changeShow}
        style={{ display: Show ? "block" : "none" }}
      ></div>
      <div
        className="Navbar-right-hambar-list"
        onClick={changeShow}
        style={{ display: Show ? "block" : "none" }}
      >
        <span className="flex">
          <p className="black Navbar-right-hambar-list_Head">
            {" "}
            Shiksha Welfare
          </p>
        </span>
        <hr />
        <p className="nav-link-item" onClick={NavRoute} href="/">
          HOME
        </p>

        <p className="nav-link-item" onClick={NavRoute} href="/mentors">
          SHIKSHA KE MENTORS
        </p>
        <p className="nav-link-item" onClick={NavRoute} href="/shikshaStories">
          SHIKSHA STORIES
        </p>
        <p className="nav-link-item" onClick={NavRoute} href="/gallery">
          GALLERY
        </p>
        <p className="nav-link-item" onClick={NavRoute} href="/updates">
          UPDATES
        </p>
        <p className="nav-link-item" onClick={NavRoute} href="/members">
          MEMBERS
        </p>
        <p className="nav-link-item" onClick={NavRoute} href="/aboutUs">
          ABOUT US
        </p>

        {user ? (
          <p className="nav-link-item" onClick={logout}>
            Logout
          </p>
        ) : (
          <p
            className="nav-link-item"
            onClick={NavRoute}
            href="/auth?ref=login"
          >
            Member Login
          </p>
        )}

        {user && user?.superAdmin && (
          <p className="nav-link-item" onClick={NavRoute} href="/admin">
            SuperAdmin
          </p>
        )}
        <p
          className="nav-link-item nav-donate"
          onClick={NavRoute}
          // style={{ border: "0", padding: "8px 14px" }}
          href="/donate?hide=true"
        >
          DONATE NOW
        </p>
      </div>
    </div>
  );
  return Hide ? null : (
    <div className="Navbar" style={style}>
      <div className="Navbar-cover">
        <div className="Navbar-left">
          <div className="NavbarLogo" style={styleImage}>
            <img
              style={styleImage}
              src="/media/shikshaLogo.png"
              className="NavbarLogoImage"
              alt="Siksha Welfare"
              onClick={NavRoute}
              href="/"
            />
          </div>
        </div>
        {navbarRight}
        {hambar}
      </div>
    </div>
  );
}

export default Navbar;
