import { useState, useEffect } from "react";

export default function useIntersection(ref, fix = true) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = new IntersectionObserver(([entry]) => {
    setIntersecting(entry.isIntersecting);
    if (entry.isIntersecting && fix) observer.disconnect();
  });

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
