import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const directNav = (e) => {
    e.preventDefault();
    const href = e.target.attributes.href.value;
    console.log(href);
    navigate(href);
  };
  return (
    <div className="footer">
      <div className="footer_line"></div>
      <div className="footer_intro">
        <p>
          For the last 15 years, another unique initiative has been started by
          the Education Welfare Society, which has been ready to help in every
          way for the needy students and girls of the entire state and country.
        </p>
        <div className="footer_contact">
          <ul>
            <li>Deepak Rahangdale</li>
            <li>0731-4090475</li>
            <li>LG-1, Radhakrishna Plaza, 6/A, Geeta Bhawan Mandir, Indore</li>
          </ul>
        </div>
      </div>

      <div className="footer_Links">
        <h6>Useful Links</h6>
        <ul>
          <li className="hover" onClick={directNav} href="/donate">
            {" "}
            Donate Now
          </li>
          <li className="hover" onClick={directNav} href="/aboutUs">
            {" "}
            About US
          </li>
          <li className="hover" onClick={directNav} href="/privacypolicy">
            {" "}
            Privacy Policy
          </li>
          <li className="hover" onClick={directNav} href="/termsandcondition">
            Terms and Conditions
          </li>
          <li className="hover" onClick={directNav} href="/refundpolicy">
            Refund Policy
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
