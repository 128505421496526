import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import Donate from "./components/Donate/Donate";
import Gallery from "./components/Gallery/Gallery";
import { ShikshaStories } from "./components/ShikshaStories/ShikshaStories";
import LoaderThree from "./components/Loaders/LoaderThree";
import Login from "./components/Login/Login/Login";
import Register from "./components/Login/Register/Register";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useStateValue } from "./StateProvider";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
import AdminUsers from "./components/Admin/AdminUsers/AdminUsers";
import AdminPanel from "./components/Admin/panel/panel";
import MentorList from "./components/Admin/MentorList/AdminMentorList";
import Incomplete from "./components/popUp/Incomplete";
import AdminStories from "./components/Admin/Stories/AdminStories";
import PrivacyPolicy from "./components/Policies/PrivacyPolicy";
import TermsAndCondition from "./components/Policies/TermsAndCondition";
import RefundPolicy from "./components/Policies/RefundPolicy";
import Footer from "./components/Footer/Footer";
import Authentication from "./components/Login/Auth/Auth";
import ScrollToTop from "./components/elements/ScrollToTop";
import AdminMentee from "./components/Admin/AdminMentee/AdminMentee";
import AdminMembers from "./components/Admin/AdminMembers/AdminMembers";

const Mentors = React.lazy(() => import("./components/Mentors/Mentors"));
const AboutUs = React.lazy(() => import("./components/AboutUs/AboutUs"));
const Home = React.lazy(() => import("./components/Home/Home"));
const Updates = React.lazy(() => import("./components/Updates/Updates"));
const Members = React.lazy(() => import("./components/Members/Members"));
const RegisterMentor = React.lazy(() =>
  import("./components/Mentors/MentorForm/MentorForm")
);
const RegisterMentee = React.lazy(() =>
  import("./components/Mentors/MenteeForm/MenteeForm")
);

function App() {
  const auth = getAuth();
  const [, dispatch] = useStateValue();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const ref = doc(db, "Users", uid);
        await getDoc(ref).then((User) => {
          console.log("User  DISPATCHING: ", User.data());
          dispatch({
            type: "SET_USER",
            user: User.data(),
          });
        });
      } else {
        console.log(`User Logout`);
        dispatch({
          type: "CLEAR_USER",
        });
      }
    });
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Incomplete />
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<LoaderThree />}>
                <Home />
              </Suspense>
            }
          ></Route>

          <Route
            path="/mentors"
            element={
              <Suspense fallback={<LoaderThree />}>
                <Mentors />
              </Suspense>
            }
          ></Route>
          <Route
            path="/aboutUs"
            element={
              <Suspense fallback={<LoaderThree />}>
                <AboutUs />
              </Suspense>
            }
          ></Route>
          <Route
            path="/updates"
            element={
              <Suspense fallback={<LoaderThree />}>
                <Updates />
              </Suspense>
            }
          ></Route>
          <Route
            path="/members"
            element={
              <Suspense fallback={<LoaderThree />}>
                <Members />
              </Suspense>
            }
          ></Route>
          <Route
            path="/registermentor"
            element={
              <Suspense fallback={<LoaderThree />}>
                <RegisterMentor />
              </Suspense>
            }
          ></Route>
          <Route
            path="/registermentee"
            element={
              <Suspense fallback={<LoaderThree />}>
                <RegisterMentee />
              </Suspense>
            }
          ></Route>
          <Route path="/donate" element={<Donate />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/shikshaStories" element={<ShikshaStories />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route>

          {/* Admin Routes */}
          <Route path="/admin" element={<AdminPanel />}></Route>
          <Route path="/admin/users" element={<AdminUsers />}></Route>
          <Route path="/admin/mentors" element={<MentorList />}></Route>
          <Route path="/admin/stories" element={<AdminStories />}></Route>
          <Route path="/admin/mentees" element={<AdminMentee />}></Route>
          <Route path="/admin/members" element={<AdminMembers />}></Route>

          <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/refundpolicy" element={<RefundPolicy />}></Route>
          <Route path="/auth" element={<Authentication />}></Route>
          <Route
            path="/termsandcondition"
            element={<TermsAndCondition />}
          ></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
