import React, { useEffect, useState } from "react";
import "./Incomplete.scss";

function Incomplete() {
  const [show, setShow] = useState(true);
  const changeShow = (e) => {
    e.preventDefault();
    setShow(false);
    try {
      window.localStorage.setItem("showIncompletePopUp", "hide");
    } catch {}
  };
  useEffect(() => {
    const IncompletePopUp = window.localStorage.getItem("showIncompletePopUp");
    if (IncompletePopUp === "hide") {
      setShow(false);
    }
  }, []);
  return (
    show && (
      <div className="incomplete">
        <div className="incomplete_popUp">
          <h3 className="incomplete_heading"> Under Construction</h3>
          <p className="incomplete_content">
            We're working hard to make this website available to everyone. Till
            then you are most welcome to see the functioning and share your
            experience with Shiksha Welfare.
            <br />
            <small>
              You can still login as a member, post stories and register as
              mentor/mentee.
            </small>
          </p>
          <button onClick={changeShow}> Continue To Website</button>
        </div>
      </div>
    )
  );
}

export default Incomplete;
