// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, addDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyD556MJbpGe9yghohbMk2RSKyKSY359NXQ",
  authDomain: "shiksha-welfare.firebaseapp.com",
  projectId: "shiksha-welfare",
  storageBucket: "shiksha-welfare.appspot.com",
  messagingSenderId: "186127422618",
  appId: "1:186127422618:web:8ca7af3f337dc29e02fb23"
};
initializeApp(firebaseConfig);
const db = getFirestore();
const storage = getStorage();

export { db, collection, getDocs, addDoc, storage };
