import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { useStateValue } from "../../../StateProvider";
import "../../ShikshaStories/ShikshaStories.css";
import "./AdminStories.css";

function UserStoryRow({ story, showHide, index, fetchData }) {
  const [read, setread] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [StoryName, setStoryName] = useState(story.Name);
  const [StoryDesignation, setStoryDesignation] = useState(story.Designation);
  const [StoryStory, setStoryStory] = useState(story.Story);
  const [StoryHeading, setStoryHeading] = useState(story.Heading);
  const [StoryPriority, setStoryPriority] = useState(story.priority);

  const readFullStory = (e) => {
    e.preventDefault();
    setread(!read);
  };

  const resetData = (e) => {
    e.preventDefault();
    setStoryName(story.Name);
    setStoryDesignation(story.Designation);
    setStoryStory(story.Story);
    setStoryHeading(story.Heading);
    setStoryPriority(story.priority);

    setEdit(false);
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    const storyref = doc(db, "Stories", story.id);
    try {
      await updateDoc(storyref, {
        Name: StoryName,
        Designation: StoryDesignation,
        Story: StoryStory,
        Heading: StoryHeading,
        priority: Number(StoryPriority),
      });
      setEdit(false);
      fetchData();
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  return Edit ? (
    <tr>
      <td style={{ minWidth: "30px", textAlign: "center" }}>{index}</td>
      <td style={{ minWidth: "30px", textAlign: "center" }}>
        <input
          value={StoryName}
          onChange={(e) => {
            e.preventDefault();
            setStoryName(e.target.value);
          }}
          style={{ width: "100%" }}
        ></input>
        <img
          style={{
            height: 200,
            width: 200,
            objectFit: "cover",
          }}
          src={story.Image}
          alt={story.Name}
        ></img>

        <br />
      </td>
      <td>
        <input
          value={StoryDesignation}
          onChange={(e) => {
            e.preventDefault();
            setStoryDesignation(e.target.value);
          }}
          style={{ width: "100%" }}
        ></input>
      </td>
      <td style={{ minWidth: "30px", textAlign: "center" }}>
        <input
          type="number"
          value={StoryPriority}
          onChange={(e) => {
            e.preventDefault();
            setStoryPriority(e.target.value);
          }}
          style={{ width: "100%" }}
        ></input>
      </td>
      <td>
        <input
          value={StoryHeading}
          onChange={(e) => {
            e.preventDefault();
            setStoryHeading(e.target.value);
          }}
          style={{ width: "100%" }}
        ></input>
      </td>
      <td>
        <p>{story.show ? <h5>Visible</h5> : <h5>Hidden</h5>}</p>
      </td>
      {read ? (
        <td>{story.Story}</td>
      ) : (
        <td
          className="ShowOneLineOnly"
          style={{ border: "none", borderTop: "1px solid grey" }}
        >
          <textarea
            value={StoryStory}
            onChange={(e) => {
              e.preventDefault();
              setStoryStory(e.target.value);
            }}
            style={{ width: "100%", height: "100%" }}
          ></textarea>
        </td>
      )}
      <td className="hover" onClick={readFullStory}>
        -
      </td>

      <td>
        <button
          type="submit"
          className="green hover"
          style={{
            textAlign: "center",
            width: "100%",
            border: "1px solid olive",
          }}
          onClick={saveChanges}
        >
          Save
        </button>
        <button
          className="red hover"
          style={{
            textAlign: "center",
            width: "100%",
            marginTop: "5px",
            border: "1px solid tomato",
          }}
          onClick={resetData}
        >
          Cancel
        </button>
      </td>
    </tr>
  ) : (
    <tr>
      <td style={{ minWidth: "30px", textAlign: "center" }}>{index}</td>

      <td>
        {story.Name}
        {read && (
          <img
            style={{
              height: 200,
              width: 200,
              objectFit: "cover",
            }}
            src={story.Image}
            alt={story.Name}
          ></img>
        )}
        <br />
      </td>
      <td>{story.Designation}</td>
      <td style={{ minWidth: "30px", textAlign: "center" }}>
        {story.priority}
      </td>
      <td>{story.Heading}</td>

      <td>
        <button
          onClick={(e) => showHide({ e, story })}
          style={{
            color: story.show ? "tomato" : "lime",
          }}
        >
          {story.show ? <h5>Hide</h5> : <h5>Show</h5>}
        </button>
      </td>
      {read ? (
        <td>{story.Story}</td>
      ) : (
        <td
          className="ShowOneLineOnly"
          style={{
            border: "none",
            borderTop: "1px solid grey",
            minWidth: "300px",
          }}
        >
          {story.Story}
        </td>
      )}
      <td className="hover" onClick={readFullStory}>
        {read ? "Less" : "more..."}
      </td>

      <td
        className="link hover"
        onClick={(e) => {
          e.preventDefault();
          setEdit(true);
        }}
        style={{ textAlign: "center" }}
      >
        <img
          alt="edit pencil"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAAuklEQVRIie3VoQrCQAAG4A+x+xJisxnMghisRl9iyahN38Jq8Wl8AItVMVk16GAOhxN2N4T9cOF2cN/9HOxo8udJcMEBg1joCvfMuGIYG83ib81boU/ySgfb0EhR63MIaFUCT6pG0403ubVFZm0ZCk3Hp+b5b5WjRc2joMHwMmiUO23QBv05E8/39Bta6c8hzbwOFMZ1oNCLifZzG3cxwgy3UCisFbeahkLhKNI9tnPz0wvdYR8SbhItDwJbnBVPxJOqAAAAAElFTkSuQmCC"
        />
      </td>
    </tr>
  );
}

function AdminStories() {
  const [Stories, setStories] = useState([]);
  const fetchData = async () => {
    const q = query(collection(db, "Stories"), orderBy("priority", "asc"));
    const querySnapshot = await getDocs(q);
    const stories = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const details = {
        id: doc.id,
        ...data,
      };
      stories.push(details);
    });

    setStories(stories);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [{ user }] = useStateValue();

  const showHide = async ({ e, story }) => {
    e.preventDefault();
    const ref = doc(db, "Stories", story.id);
    const UpdatedStory = story;
    UpdatedStory.show = !story.show;
    await setDoc(ref, UpdatedStory);
    fetchData();
  };
  // const sortUpdate = (e) => {
  //   e.preventDefault();
  //   const func = e.target.value;
  //   console.log(func);
  //   try {
  //     const s = Stories?.sort((a, b) => (a[func] > b[func] ? 1 : -1));
  //     console.log("Stories  :", s);
  //     setStories([...s]);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    user &&
    user.superAdmin && (
      <div className="AdminStories">
        {/* <div className="AdminStories_Sorting">
          <h6>Sort By</h6>
          <select onChange={sortUpdate}>
            <option value="priority" selected>
              Priority
            </option>
            <option value="Name" selected>
              Name
            </option>
          </select>
        </div> */}
        <div className="AdminShikshaStories_Content ">
          <table>
            <tr>
              <th>S. No.</th>
              <th>Name</th>
              <th>Designation</th>
              <th>Priority</th>
              <th>Heading</th>
              <th>Show/ Hide</th>
              <th>Story</th>
              <th>Read more</th>
              <th>Edit/ Save</th>
            </tr>
            {Stories.map((story, index) => (
              <UserStoryRow
                story={story}
                index={index + 1}
                key={story.id}
                showHide={showHide}
                fetchData={fetchData}
              />
            ))}
          </table>
        </div>
      </div>
    )
  );
}

export default AdminStories;
