import React from "react";
import "./LoaderThree.css";
function LoaderThree() {
  return (
    <div className="loaderThree">
      <h2>SHIKSHA WELFARE FOUNDATION </h2>
      <h5>JOIN HANDS TO EDUCATE INDIA</h5>
    </div>
  );
}

export default LoaderThree;
