import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../../firebase";
import "./ConnectToShiksha.css";
function ConnectToShiksha() {
  const [Active, setActive] = useState(false);
  const sendDetails = async (e) => {
    e.preventDefault();
    const details = {
      Name: e.target.Donate_Name?.value,
      Mobile: e.target.Donate_Mobile?.value,
      Email: e.target.Donate_Email?.value,
      Address: e.target.Donate_Address?.value,
      State: e.target.Donate_State?.value,
      City: e.target.Donate_City?.value,
      PinCode: e.target.Donate_PinCode?.value,
      timestamp: serverTimestamp(),
      donated: false,
    };
    try {
      const docRef = await addDoc(collection(db, "Donaters"), details);
      console.log("Document written with ID: ", docRef.id);
      setActive(true);
    } catch (e) {
      console.log("Error adding document: ", e);
      alert("Error Sending Details, Please try again");
    }
  };
  return Active ? (
    <h3 className="green">
      Your Details has been submitted Succesfull, You will soon receive a call
      from Shiksha Welfare Organisation
    </h3>
  ) : (
    <div className="ConnectToShiksha">
      <div className="ConnectToShiksha_left">
        <form onSubmit={sendDetails} className="ConnectToShiksha_form">
          <h3>Contact Details</h3>
          <div className="ConnectToShiksha_details flex col">
            <div className="ConnectToShiksha_detail flex">
              <input
                required
                id="Donate_Name"
                name="Name"
                placeholder="Name *"
              ></input>
              <input
                required
                type="number"
                id="Donate_Mobile"
                name="Mobile"
                placeholder="Mobile *"
              ></input>
            </div>
            <div className="ConnectToShiksha_detail flex">
              <input
                required
                type="email"
                id="Donate_Email"
                name="Email"
                placeholder="Email *"
              ></input>
            </div>
            <div className="ConnectToShiksha_detail flex">
              <input
                required
                id="Donate_Address"
                name="Address"
                placeholder="Address *"
              ></input>
            </div>
            <div className="ConnectToShiksha_detail flex">
              <input
                value="India"
                id="Donate_Country"
                name="Country"
                placeholder="Country *"
              ></input>
              <input
                required
                id="Donate_State"
                name="State"
                placeholder="State *"
              ></input>
            </div>
            <div className="ConnectToShiksha_detail flex">
              <input
                required
                id="Donate_City"
                name="City"
                placeholder="City *"
              ></input>
              <input
                required
                type="number"
                id="Donate_PinCode"
                name="Pincode"
                placeholder="Pincode *"
              ></input>
            </div>
          </div>
          <button type="submit">CONTACT ME FOR DONATION</button>
        </form>
      </div>
      <div className="ConnectToShiksha_right"></div>
    </div>
  );
}

export default ConnectToShiksha;
