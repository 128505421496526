import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateValue } from "../../../StateProvider";
import "./Auth.css";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";

import { GoogleAuthProvider } from "firebase/auth";

function AuthLogin() {
  const [{ user }] = useStateValue();
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.Authlogin_username?.value.split(" ").join("");
    const password = e.target.Authlogin_password.value;
    console.log(`Email : ${email} \n Password : ${password}`);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(`Login Succesfull`);
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Error : ${errorCode}, ${errorMessage}`);
        alert(errorMessage);
      });
  };
  const loginWithGoogleAuth = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const name = user.displayName;
        const email = user.email;
        const ref = doc(db, "Users", user.uid);
        const docSnap = await getDoc(ref);
        const userDetails = {
          name,
          email,
          uid: user.uid,
          admin: false,
          date: serverTimestamp(),
          superAdmin: false,
        };

        if (!docSnap.exists()) {
          setDoc(ref, userDetails);
        }

        navigate("/");
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log(
          `Error : ${errorCode}, ${errorMessage}, \n email : ${email} , credential : ${credential}`
        );
        alert(errorMessage);
      });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);
  const navToRegister = () => {
    navigate("/auth?ref=register");
  };
  return (
    <div className="AuthLogin">
      <h6>SHIKHSA WELFARE SOCIETY</h6>
      <div className="AuthLogin_form-Cover">
        <h4>Log In.</h4>
        <small>
          Log in with your data that you entered during your registration.
        </small>
        <form onSubmit={handleSubmit} className="AuthLogin_form">
          <table className="AuthLogin_table">
            <tr>
              <td>
                <label htmlFor="Authlogin_username">Your E-mail</label>

                <input
                  type="email"
                  id="Authlogin_username"
                  name="Email"
                ></input>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="Authlogin_password">Password</label>

                <input
                  type="password"
                  id="Authlogin_password"
                  name="Password"
                ></input>
              </td>
            </tr>
          </table>
          <button type="submit">Log In</button>
        </form>
        <button className="google_SignIn" onClick={loginWithGoogleAuth}>
          Google Sign In
        </button>
        <hr />
        <p className="hover AuthRegisterMember link" onClick={navToRegister}>
          <small>If you are new,</small>
          <br />
          Register as a member.
        </p>
        <hr />
      </div>
    </div>
  );
}

function AuthRegister() {
  const [{ user }] = useStateValue();
  const auth = getAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.Authlogin_Name.value;
    const email = e.target.Authlogin_username.value.split(" ").join("");
    const password = e.target.Authlogin_password.value;
    console.log(`Email : ${email} \n Password : ${password}`);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(`User : ${user.uid}`);
        const ref = doc(db, "Users", user.uid);
        const userDetails = {
          name,
          email,
          uid: user.uid,
          admin: false,
          date: serverTimestamp(),
          superAdmin: false,
        };
        setDoc(ref, userDetails);
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Error : ${errorCode} : ${errorMessage}`);
        alert(errorMessage);
        // ..
      });
  };
  const navigate = useNavigate();
  const navToRegister = () => {
    navigate("/auth?ref=login");
  };
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <div className="AuthLogin">
      <h6>SHIKHSA WELFARE SOCIETY</h6>
      <div className="AuthLogin_form-Cover">
        <h4>Register as Member</h4>
        <small>Enter your details to complete the registration process.</small>
        <form onSubmit={handleSubmit} className="AuthLogin_form">
          <table className="AuthLogin_table">
            <tr>
              <td>
                <label htmlFor="Authlogin_Name">Your Full Name</label>

                <input id="Authlogin_Name" name="Name"></input>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td>
                <label htmlFor="Authlogin_username">Your E-mail</label>

                <input
                  required
                  type="email"
                  id="Authlogin_username"
                  name="Email"
                ></input>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="Authlogin_password">Password</label>
                <input
                  required
                  type="password"
                  id="Authlogin_password"
                  name="Password"
                ></input>
              </td>
            </tr>
          </table>
          <button type="submit">Register</button>
        </form>
        <hr />
        <p className="hover AuthRegisterMember link" onClick={navToRegister}>
          <small>If you are a member,</small>
          <br />
          Login as a member.
        </p>
        <hr />
      </div>
    </div>
  );
}

function Auth() {
  const [State, setState] = useState("login");
  const query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    const ref = query.get("ref");
    window.scrollTo(0, 0);
    if (ref) setState(ref);
  }, [query]);
  return (
    <div className="Auth">
      <img
        src="/media/LoginRegisterBackground.jpeg"
        alt="Small Actions XLOTS OF PEOPLE = BIG CHANGE"
      />
      <div className="Auth_LoginRegister">
        {State === "login" ? <AuthLogin /> : <AuthRegister />}
      </div>
    </div>
  );
}

export default Auth;
