import React, { useEffect, useState } from "react";
import "./Gallery.css";

import { db, collection, addDoc, storage } from "../../firebase";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { query, getDocs, orderBy } from "firebase/firestore";

import Resizer from "react-image-file-resizer";
import { serverTimestamp } from "firebase/firestore";
import { useStateValue } from "../../StateProvider";
import { useRef } from "react";
import useIntersection from "../elements/useTntersection";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      900,
      900,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });
const resizeFileThumbnail = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

function ImageDisplay({ image, showLarge, grid = true }) {
  const [Style, setStyle] = useState({});
  const [Height, setHeight] = useState({});

  const testSize = (e) => {
    if (grid) {
      const imageHeight = e.target.clientHeight;
      const imagewidth = e.target.clientWidth;
      console.log("HEIGHT : ", imageHeight, "Width : ", imagewidth);
      const height = (imageHeight / imagewidth) * 359;
      if (height > 450) {
        setStyle({
          gridRowStart: "span 4",
        });
        setHeight({
          height: "600px",
        });
      } else if (height > 300) {
        setStyle({
          gridRowStart: "span 3",
        });
        setHeight({
          height: "450px",
        });
      } else if (height > 150) {
        setStyle({
          gridRowStart: "span 2",
        });
        setHeight({
          height: "300px",
          // objectFit: "cover",
        });
      } else {
        setStyle({
          gridRowStart: "span 1",
        });
        setHeight({
          height: "150px",
          // objectFit: "cover",
        });
      }
    } else {
      setHeight({
        width: "400px",
        height: "400px",
        objectFit: "cover",
      });
    }
  };
  return (
    <div
      className="Gallery_item"
      style={{ ...Style, backgroundImage: `url("${image.thumbnail}")` }}
      onClick={(e) => {
        showLarge(e, image);
      }}
    >
      <img
        alt="gallery"
        style={Height}
        src={image.small}
        loading="lazy"
        onLoad={testSize}
      ></img>
    </div>
  );
}

function GalleryHighlights() {
  const [Highlights, setHighlights] = useState([]);
  const [{ user }] = useStateValue();

  const fetchHighlights = async () => {
    const highlights = [];
    const q = query(collection(db, "Highlights"), orderBy("timestamp", "desc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      highlights.push(doc.data());
    });
    setHighlights(highlights);
  };
  const ImageUrl = async (image) => {
    const file = await resizeFile(image);
    const fileThumb = await resizeFileThumbnail(image);

    const storageRef = ref(storage, `gallery/Highlights/${image.name}/small`);
    await uploadBytes(storageRef, file);

    const storageRefLarge = ref(
      storage,
      `gallery/Highlights/${image.name}/large`
    );
    await uploadBytes(storageRefLarge, image);

    const storageRefThumb = ref(
      storage,
      `gallery/Highlights/${image.name}/Thumbnail`
    );
    await uploadBytes(storageRefThumb, fileThumb);

    const url = await getDownloadURL(storageRef);
    const URL = await getDownloadURL(storageRefLarge);
    const thumbnail = await getDownloadURL(storageRefThumb);

    try {
      const docRef = await addDoc(collection(db, "Highlights"), {
        small: url,
        large: URL,
        thumbnail: thumbnail,
        timestamp: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.log("Error adding document: ", e);
      alert("Error adding document: ");
    }

    console.log("Done");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const files = e.target.Gallery_InputImages.files;
    console.log("INPUT STARTED :: ");
    Object.keys(files).map(async (file) => await ImageUrl(files[file]));
  };
  useEffect(() => {
    fetchHighlights();
  });
  const [Image, setImage] = useState("");
  const [Show, setShow] = useState(false);
  const showLarge = (e, image) => {
    e.preventDefault();
    setShow(true);
    setImage(image.large);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };
  return (
    <div className="GalleryHighlights">
      {Show ? (
        <div className="Gallery_FullImage">
          <div className="Gallery_FullImage_bg" onClick={handleClose}></div>
          <img src={Image} alt="Full" loading="eager"></img>
        </div>
      ) : (
        <></>
      )}
      <div className="GalleryHighlights_head flex">
        <p className="GalleryHighlights_Line"></p>
        <h2>HIGHLIGHTS</h2>
        <p className="GalleryHighlights_Line"></p>
      </div>
      {user && user?.superAdmin && (
        <div className="Gallery_Input">
          <form onSubmit={handleSubmit}>
            <label htmlFor="Gallery_InputImages">Upload Highlights :</label>
            <input
              type="file"
              multiple={true}
              accept="image/*"
              id="Gallery_InputImages"
            />
            <button type="submit"> SUBMIT </button>
          </form>
        </div>
      )}
      <div className="GalleryHighlights_Images ">
        {Highlights.map((image) => (
          <ImageDisplay
            image={image}
            showLarge={showLarge}
            key={image.large}
            grid={false}
          />
        ))}
      </div>
    </div>
  );
}

function Gallery() {
  const [Images, setImages] = useState([]);
  const [Image, setImage] = useState("");
  const [Show, setShow] = useState(false);
  const [{ user }] = useStateValue();

  const fetchData = async () => {
    const q = query(collection(db, "Gallery"), orderBy("timestamp", "desc"));
    const querySnapshot = await getDocs(q);
    const images = [];
    querySnapshot.forEach((doc) => {
      images.push(doc.data());
    });
    setImages(images);
  };
  const [ImagesDisplay, setImagesDisplay] = useState([]);
  const [IndexAdd, setIndexAdd] = useState(0);
  const fixImageShow = 20;

  const refrence = useRef();
  const inview = useIntersection(refrence, false);

  const LoadData = () => {
    if (Images.length > IndexAdd) {
      const addImages = Images.slice(IndexAdd, IndexAdd + fixImageShow);
      setIndexAdd(IndexAdd + fixImageShow);
      setImagesDisplay((x) => [...x, ...addImages]);
    }
  };
  useEffect(() => {
    LoadData();
  }, [inview, Images]);

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const [ImagesUploaded, setImagesUploaded] = useState(0);
  const ImageUrl = async (image) => {
    console.log("Start");

    const file = await resizeFile(image);
    const fileThumb = await resizeFileThumbnail(image);

    const storageRef = ref(storage, `gallery/${image.name}/small`);
    await uploadBytes(storageRef, file);

    const storageRefLarge = ref(storage, `gallery/${image.name}/large`);
    await uploadBytes(storageRefLarge, image);

    const storageRefThumb = ref(storage, `gallery/${image.name}/Thumbnail`);
    await uploadBytes(storageRefThumb, fileThumb);

    const url = await getDownloadURL(storageRef);
    const URL = await getDownloadURL(storageRefLarge);
    const thumbnail = await getDownloadURL(storageRefThumb);

    try {
      const docRef = await addDoc(collection(db, "Gallery"), {
        small: url,
        large: URL,
        thumbnail: thumbnail,
        timestamp: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
      ImagesUploaded((e) => e + 1);
    } catch (e) {
      console.log("Error adding document: ", e);
      alert("Error adding document: ");
    }

    console.log("Done");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const files = e.target.Gallery_InputImages.files;
    console.log("INPUT STARTED :: ");
    Object.keys(files).map(async (file) => await ImageUrl(files[file]));
  };
  const showLarge = (e, image) => {
    e.preventDefault();
    setShow(true);
    setImage(image.large);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };
  return (
    <div className="Gallery">
      {Show && (
        <div className="Gallery_FullImage">
          <div className="Gallery_FullImage_bg" onClick={handleClose}></div>
          <img src={Image} alt="Full" loading="eager"></img>
          {user && user?.superAdmin && <button>DELETE IMAGE</button>}
        </div>
      )}

      <div
        className="Gallery_HeadBg"
        style={{ backgroundImage: 'url("./media/GalleryHead.JPG")' }}
      ></div>

      {user && user?.superAdmin && (
        <div className="Gallery_Input">
          <form onSubmit={handleSubmit}>
            <label htmlFor="Gallery_InputImages">Upload Images :</label>
            <input
              type="file"
              multiple={true}
              accept="image/*"
              id="Gallery_InputImages"
            />
            <button type="submit"> SUBMIT </button>
          </form>
          {ImagesUploaded > 0 && <p>Images Uploaded : {ImagesUploaded}</p>}
        </div>
      )}
      <div className="Gallery_Heading">
        <h2>GALLERY</h2>
        <p className="hover">
          {" "}
          <strong>15 YEARS OF HELPING</strong>
          <br />
          <small> Captured in photos {`&`} memories </small>
          <br />
        </p>
      </div>
      <GalleryHighlights />
      <div className="GalleryHighlights_head flex">
        <p className="GalleryHighlights_Line"></p>
        <h2>JOURNEY</h2>
        <p className="GalleryHighlights_Line"></p>
      </div>
      <div className="Gallery_items">
        {ImagesDisplay.map((image) => (
          <ImageDisplay image={image} showLarge={showLarge} key={image.large} />
        ))}
      </div>
      <div className="Gallery_end_Load_More" ref={refrence}></div>
    </div>
  );
}

export default Gallery;
